/* eslint-disable react/jsx-no-target-blank */
import * as React from 'react';
import { DappUI } from '@elrondnetwork/dapp-core';

import { contractAddress, tokenPrice } from 'config';

const TopInfo = ({ munchkinsLeft }: any) => {
  return (
    <div className='text-white' data-testid='topInfo'>
      <div className='mb-4'>
        <span className='opacity-6 mr-1'>Contract address:</span>
        <a
          href={'https://explorer.elrond.com/accounts/' + contractAddress}
          target='_blank'
          rel='norefferer'
          className='contract-link'
        >
          <span data-testid='contractAddress'> {contractAddress}</span>
        </a>
      </div>
      <div>
        <h2 className='py-2'>
          <DappUI.Denominate
            value={Math.floor(munchkinsLeft / 100000000)}
            denomination={8}
            decimals={0}
            token='MUNCHKIN'
            data-testid='balance'
          />{' '}
          left
        </h2>
        <h4 className=' py-2'>
          Price: 1 EGLD /{' '}
          <DappUI.Denominate
            value={tokenPrice}
            denomination={8}
            decimals={0}
            token='MUNCHKIN'
            data-testid='balance'
          />{' '}
        </h4>
      </div>
    </div>
  );
};

export default TopInfo;
