export const contractAddress =
  'erd1qqqqqqqqqqqqqpgqa4f6havn8kjcvth23mlkpfeklyy2yvav48ksvwvjh3';

// eslint-disable-next-line quotes
export const dAppName = "Morfilio's private Munchkin sale";

export const minValue = 0.02;
export const maxValue = 10;
export const tokenPrice = 50000000;
export const token = 'MUNCHKIN';
