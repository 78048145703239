/* eslint-disable react/jsx-no-target-blank */
import React from 'react';

import {
  faHourglass,
  faCheck,
  faExclamation,
  faSearch
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Transactions = ({
  transactionStatus,
  setHasPendingTransactions
}: any) => {
  const [transaction, setTransaction] = React.useState<any>({});

  React.useEffect(() => {
    const length = Object.keys(transactionStatus).length;

    if (length > 0) {
      if (transactionStatus.transactions != undefined) {
        setTransaction(transactionStatus.transactions[0]);
        if (transactionStatus.transactions[0].status == 'success') {
          setHasPendingTransactions(false);
        }
      }
    }
  }, [transactionStatus]);

  return transactionStatus.transactions == undefined ? (
    <></>
  ) : (
    <div className='card-body text-center d-flex flex-column justify-content-center'>
      <div className='dapp-icon icon-medium'>
        {transaction.status == 'pending' ? (
          <FontAwesomeIcon
            icon={faHourglass}
            className='mx-auto text-muted fa-3x mb-2'
          />
        ) : transaction.status == 'success' ||
          transaction.status == 'completed' ? (
          <FontAwesomeIcon
            icon={faCheck}
            className='mx-auto text-muted fa-3x mb-2'
          />
        ) : (
          <FontAwesomeIcon
            icon={faExclamation}
            className='mx-auto text-muted fa-3x mb-2'
          />
        )}
      </div>
      <div>
        {transaction.status == 'pending' ? (
          <div className='mt-4'>
            <p>
              Pending transaction.
              <br />
              Please Wait...
            </p>
          </div>
        ) : transaction.status == 'success' ||
          transaction.status == 'completed' ? (
          <div className='mt-4'>
            <p>You have been blessed by Munchkins, enjoy your cats!</p>
          </div>
        ) : (
          <div className='mt-4'>
            <p>Error. Please try again</p>
            <p>Message: {transaction.errorMessage}</p>
          </div>
        )}
      </div>
      {transactionStatus.transactions == undefined ? (
        ''
      ) : (
        <p className='empty-heading mt-3'>
          Tx hash: {transaction.hash}{' '}
          <a
            href={
              'https://explorer.elrond.com/transactions/' + transaction.hash
            }
            rel='norefferer'
            target='_blank'
          >
            <FontAwesomeIcon
              icon={faSearch}
              className='mx-auto text-muted mb-2'
            />
          </a>
        </p>
      )}
    </div>
  );
};

export default Transactions;
